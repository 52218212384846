import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Person} from '../../../object/Person';
import {Country} from '../../../object/Country';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';
import {API} from '../../../API';
import {Log} from '../../../object/Log';

// tslint:disable:variable-name
@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.css']
})
export class PersonComponent implements OnInit {
  private sub: any;

  availableMembershipYears = [];
  currentYear = 0;

  isLoading = false;
  isSaving = false;

  frmCtrlVillage = new FormControl();
  places: string[];
  filteredPlaces: string[];

  id: number;

  person: Person;

  countries: Country[] = [];

  logs: Log[] = [];

  isEditing = false;

  constructor(private route: ActivatedRoute, private http: HttpClient, private ngZone: NgZone) {
  }

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize(): void {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this.loadCountries();
    this.load();
  }

  updateData(id: number, places: string[] = [], isEditing: boolean = false): void {
    this.id = id;
    this.load();
    this.logs = [];
    this.places = places;
    this.isEditing = isEditing;
  }

  // todo: load person
  private load(): void {
    if (this.id == null || this.id === 0) {
      return;
    }

    if (this.id === -1) {
      this.person = new class implements Person {
        memberships: number[] = [];
        bank_bic = '';
        country: Country;
        country_id = 0;
        bank_depositor = '';
        email = '';
        firma = '';
        first_name = '';
        bank_iban = '';
        id = -1;
        interests = '';
        is_confirmed = 1;
        is_favorite = 0;
        address_city = '';
        address_street = '';
        address_zipcode = '';
        last_name = '';
        member_id = '';
        notes = '';
        phone_line = '';
        phone_mobile = '';
        postcode = '';
        pre_salutation = '';
        is_receiving_mail_news = 0;
        is_receiving_email_news = 0;
        is_receiving_seminar_news = 0;
        role = '';
        salutation = '';
        street = '';
        time_created: number;
        time_updated: number;
        title = '';
      };
    }

    this.isEditing = false;

    if (this.id > 0) {
      this.isLoading = true;
      API.person(this.http, this.id, (data) => {
        this.person = data;

        this.frmCtrlVillage.setValue(this.person.address_city);
        this.updateAvailableMembershipYears();
      }, (error) => {
        alert(error);
      }, () => {
        this.isLoading = false;
      });
    } else {
      this.updateAvailableMembershipYears();
    }

    this.loadLogs();
  }

  private loadLogs(): void {
    if (this.id > 0) {
      this.isLoading = true;
      API.personLogs(this.http, this.id, (data) => {
        this.logs = data;
      }, (error) => {
        alert(error);
      }, () => {
        this.isLoading = false;
      });
    } else {
      this.updateAvailableMembershipYears();
    }
  }

  private save(): void {
    if (this.id == null || this.id === 0) {
      return;
    }

    this.isSaving = true;

    if (this.id === -1) {
      API.personCreate(this.http, this.person, (data) => {
        if (this.id === -1) {
          this.id = data.id;
        }
        this.load();
        this.isEditing = false;
      }, (error) => {
        switch (error) {
          case 'email-already-in-use':
            alert('Diese E-Mail-Adresse ist bereits in Verwendung!');
            break;
          default:
            alert(`Unbekannter Fehler: ${error}`);
        }
      }, () => {
        this.isLoading = false;
      });
    } else {
      API.personUpdate(this.http, this.person, (data) => {
        this.load();
        this.isEditing = false;
      }, (error) => {
        switch (error) {
          case 'email-already-in-use':
            alert('Diese E-Mail-Adresse ist bereits in Verwendung!');
            break;
          default:
            alert(`Unbekannter Fehler: ${error}`);
        }
      }, () => {
        this.isLoading = false;
      });
    }
  }

  private loadCountries(): void {
    API.countries(this.http, (data) => {
      this.countries = data;
    }, (error) => {
      alert('Fehler beim Laden der Länder. (Fehler: ' + error + ')');
    });
  }

  deletePerson(): void {
    if (this.person.id == null || this.person.id <= 0) {
      return;
    }

    if (!confirm('Person wirklich löschen?')) {
      return;
    }

    API.personDelete(this.http, this.person.id, () => {
      this.updateData(0);
    }, (error) => {
      alert(error);
    });
  }

  cancel(): void {
    this.id = 0;
    this.load();
  }

  private filter(value): void {
    const val = value !== undefined ? value.toLowerCase() : '';

    this.filteredPlaces = this.places.filter(place => place.toLowerCase().indexOf(val) === 0);
  }

  private _filter(value: string): string[] {
    console.log(value);
    // if (value !== '') {
    this.person.address_city = value;
    // }
    const filterValue = value.toLowerCase();

    return this.places.filter(place => place.toLowerCase().indexOf(filterValue) === 0);
  }

  public removeMembershipYear(year: number): void {
    for (let i = this.person.memberships.length - 1; i >= 0; i--) {
      if (this.person.memberships[i] === year) {
        this.person.memberships.splice(i, 1);
      }
    }

    this.updateAvailableMembershipYears();
  }

  public removeAvailableMembershipYear(year: number): void {
    for (let i = this.availableMembershipYears.length - 1; i >= 0; i--) {
      if (this.availableMembershipYears[i] === year) {
        this.availableMembershipYears.splice(i, 1);
      }
    }
  }

  private updateAvailableMembershipYears(): void {
    const currentYear = new Date().getFullYear();
    this.availableMembershipYears = [];
    this.availableMembershipYears.push(currentYear - 1);
    this.availableMembershipYears.push(currentYear);
    this.availableMembershipYears.push(currentYear + 1);
    this.availableMembershipYears.push(currentYear + 2);

    for (let i = this.person.memberships.length - 1; i >= 0; i--) {
      if (this.availableMembershipYears.includes(this.person.memberships[i])) {
        this.removeAvailableMembershipYear(this.person.memberships[i]);
      }
    }
  }

  private addMembershipYear(year: number): void {
    this.person.memberships.push(year);

    this.updateAvailableMembershipYears();
  }

  public isMember(): boolean {
    return this.person.memberships.includes(new Date().getFullYear());
  }
}

<main>
  <div class="wrapper">
    <div class="list" style="overflow:scroll;">
      <div class="actions">
        <button mat-button color="accent" (click)="create()">
          <mat-icon class="format">add</mat-icon>
          NEU
        </button>
        <button mat-button color="accent" (click)="load()">
          <mat-icon class="format">refresh</mat-icon>
          AKTUALISIEREN
        </button>
        <button mat-button color="primary" (click)="print()">
          <mat-icon class="format">print</mat-icon>
          LISTE EXPORTIEREN
        </button>
      </div>

      <mat-form-field id="input-search">
        <input matInput placeholder="Suche" [(ngModel)]="searchPerson">
        <button mat-button *ngIf="searchPerson" matSuffix mat-icon-button aria-label="Clear" (click)="searchPerson=''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <div class="search-info">In der Datenbank sind <b>{{people.length}}</b> Personen gespeichert.</div>

      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
      <mat-list *ngIf="!isLoading">
        <mat-list-item *ngFor="let person of people | filterPeople: searchPerson" (click)="openPerson(person.id)"
                       [class.active]="person.id == selectedPersonId">
          <mat-icon mat-list-icon>person</mat-icon>
          <h4 mat-line *ngIf="person.last_name != '' || person.first_name != ''; else email">
            <b>{{person.last_name}}</b>&nbsp;{{person.first_name}}
          </h4>
          <p mat-line *ngIf="person.member_id != ''">{{person.member_id}}</p>
          <ng-template #email>{{person.email}}</ng-template>
        </mat-list-item>
      </mat-list>
    </div>
    <div class="detail">
      <button *ngIf="selectedPersonId > 0" mat-icon-button (click)="openPerson(0)"
              style="margin-top: -4px;"
              matTooltip="Ansicht schließen">
        <mat-icon>close</mat-icon>
      </button>
      <app-person></app-person>
    </div>
  </div>
</main>

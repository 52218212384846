import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {registerLocaleData} from '@angular/common';
import localeDE from '@angular/common/locales/de';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatOptionModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatChipsModule} from '@angular/material/chips';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {DashboardComponent} from './site/dashboard/dashboard.component';
import {HomeComponent} from './site/dashboard/home/home.component';
import {PeopleComponent} from './site/dashboard/people/people.component';
import {PersonComponent} from './site/dashboard/person/person.component';
import {LoginComponent} from './site/login/login.component';
import {NotFoundComponent} from './site/error/not-found/not-found.component';
import {FilterPeoplePipe} from './filter/FilterPipe';
import {IbanPipePipe} from './filter/IBANPipe';
import {ReplaceLineBreaksPipe} from './filter/ReplaceLineBreaksPipe';
import {HttpClientModule} from '@angular/common/http';

registerLocaleData(localeDE);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HomeComponent,
    PeopleComponent,
    PersonComponent,
    LoginComponent,
    NotFoundComponent,

    FilterPeoplePipe,
    IbanPipePipe,
    ReplaceLineBreaksPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatOptionModule,
    MatSelectModule,
    MatChipsModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatExpansionModule,
    MatGridListModule,
    FormsModule,
  ],
  providers: [{provide: LOCALE_ID, useValue: 'de-AT'}],
  bootstrap: [AppComponent]
})
export class AppModule {
}

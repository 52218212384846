<div id="card-login">
  <img src="./assets/logo.png" id="img-logo">

  <h1>ADMINISTRATION</h1>

  <mat-form-field>
    <input matInput placeholder="Benutzer" type="text" [(ngModel)]="username" [disabled]="isSigningIn">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Passwort" type="password" [(ngModel)]="password" [disabled]="isSigningIn">
  </mat-form-field>

  <button mat-raised-button color="primary" (click)="signIn()" [disabled]="isSigningIn">ANMELDEN</button>
</div>

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {PersonComponent} from '../person/person.component';
import {Person} from '../../../object/Person';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {API} from '../../../API';
import {URLs} from '../../../constant/URLs';
import {APIHelper} from '../../../helper/APIHelper';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.css']
})
export class PeopleComponent implements OnInit, AfterViewInit {

  @ViewChild(PersonComponent) child: PersonComponent;

  selectedPersonId = 0;
  people: Person[] = [];

  places: string[] = [
    'Schärding',
    'Sankt Florian am Inn',
    'Grieskirchen',
    'Neuhaus am Inn',
    'Ried im Innkreis',
    'Taufkirchen an der Pram'
  ];

  searchPerson = '';

  isLoading = false;

  constructor(private router: Router, private http: HttpClient) {
  }

  ngOnInit(): void {
    this.isLoading = true;
  }

  ngAfterViewInit(): void {
    this.load();
  }

  public load(): void {
    this.isLoading = true;
    this.openPerson(0);
    API.people(this.http, (data) => {
      this.people = data as Person[];
      this.isLoading = false;
    }, (error) => {
      alert(error);
    });

    this.loadPlaces();
  }

  create(): void {
    this.openPerson(-1, true);
  }

  openPerson(id: number, isEditing: boolean = false): void {
    this.selectedPersonId = id;
    this.child.updateData(this.selectedPersonId, this.places, isEditing);
  }

  print(): void {
    const credentials = APIHelper.getCredentials();
    const encrypted = btoa(`${credentials.username}:${credentials.password}`);

    console.log(`${URLs.PEOPLE_EXPORT}/${encrypted}`);

    window.location.href = `${URLs.PEOPLE_EXPORT}/${encrypted}`;
  }

  private loadPlaces(): void {
    API.places(this.http, (data) => {
      this.places = data;
      // this.filter(undefined);
    }, (error) => {
      alert(error);
    }, () => {
      this.isLoading = false;
    });
  }

}

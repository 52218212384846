import {Credentials} from '../object/Credentials';

export class APIHelper {
  public static getCredentials(): Credentials {
    const cred = new Credentials();
    cred.username = localStorage.username;
    cred.password = localStorage.password;
    return cred;
  }
}

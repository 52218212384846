export class URLs {
  public static readonly DOMAIN = 'https://api.kulturverein-schaerding.at/';

  public static readonly SIGN_IN = URLs.DOMAIN + 'sign/in';
  public static readonly PEOPLE = URLs.DOMAIN + 'people';
  public static readonly PERSON = URLs.DOMAIN + 'person';
  public static readonly PERSON_UPDATE = URLs.DOMAIN + 'person/update';
  public static readonly PERSON_CREATE = URLs.DOMAIN + 'person/create';
  public static readonly PERSON_DELETE = URLs.DOMAIN + 'person/delete';
  public static readonly PERSON_LOGS = URLs.DOMAIN + 'person/logs';
  public static readonly PEOPLE_EXPORT = URLs.DOMAIN + 'people/export';
  public static readonly COUNTRIES = URLs.DOMAIN + 'countries';
  public static readonly PLACES = URLs.DOMAIN + 'places';
}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  mainMenu: any = [
    // {link: 'home', icon: 'show_chart', text: 'Übersicht'},
    // {link: 'articles', icon: 'list', text: 'Artikel'},
    {link: 'people', icon: 'people', text: 'Personen'}
  ];

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  signOut(): void {
    localStorage.clear();
    this.router.navigateByUrl('/');
  }

}

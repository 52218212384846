import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {APIHelper} from './helper/APIHelper';
import {Credentials} from './object/Credentials';
import {URLs} from './constant/URLs';
import {Person} from './object/Person';

export class API {
  private static readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  public static STATE_OK = 'ok';
  public static STATE_FAILED = 'failed';

  private static readonly defFuncWithParam = (_) => {
  };
  private static readonly defFunc = () => {
  };

  public static signIn(http: HttpClient, credentials: Credentials,
                       success = this.defFuncWithParam,
                       error = this.defFuncWithParam,
                       always = this.defFunc): void {
    http
      .post(URLs.SIGN_IN, {}, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`,
        })
      })
      .subscribe((response: APIResponse) => {
          if (response.status === 200) {
            success(response.data);
          } else {
            error(response.error);
          }
          always();
        },
        response => {
          error(response.error.error);
          always();
        });
  }

  public static people(http: HttpClient,
                       success = this.defFuncWithParam,
                       error = this.defFuncWithParam,
                       always = this.defFunc): void {

    const credentials = APIHelper.getCredentials();

    http
      .post(URLs.PEOPLE, {}, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`,
        })
      })
      .subscribe((response: APIResponse) => {
          if (response.status === 200) {
            success(response.data);
          } else {
            error(response.error);
          }
          always();
        },
        response => {
          error(response.error.error);
          always();
        });
  }

  public static places(http: HttpClient,
                       success = this.defFuncWithParam,
                       error = this.defFuncWithParam,
                       always = this.defFunc): void {

    const credentials = APIHelper.getCredentials();

    http
      .post(URLs.PLACES, {}, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`,
        })
      })
      .subscribe((response: APIResponse) => {
          if (response.status === 200) {
            success(response.data);
          } else {
            error(response.error);
          }
          always();
        },
        response => {
          error(response.error.error);
          always();
        });
  }

  public static personLogs(http: HttpClient, id: number,
                           success = this.defFuncWithParam,
                           error = this.defFuncWithParam,
                           always = this.defFunc): void {

    const credentials = APIHelper.getCredentials();

    http
      .post(URLs.PERSON_LOGS, {id}, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`,
        })
      })
      .subscribe((response: APIResponse) => {
          if (response.status === 200) {
            success(response.data);
          } else {
            error(response.error);
          }
          always();
        },
        response => {
          error(response.error.error);
          always();
        });
  }

  public static person(http: HttpClient, id: number,
                       success = this.defFuncWithParam,
                       error = this.defFuncWithParam,
                       always = this.defFunc): void {

    const credentials = APIHelper.getCredentials();

    http
      .post(URLs.PERSON, {id}, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`,
        })
      })
      .subscribe((response: APIResponse) => {
          if (response.status === 200) {
            success(response.data);
          } else {
            error(response.error);
          }
          always();
        },
        response => {
          error(response.error.error);
          always();
        });
  }

  public static personUpdate(http: HttpClient, personIn: Person,
                             success = this.defFuncWithParam,
                             error = this.defFuncWithParam,
                             always = this.defFunc): void {

    const credentials = APIHelper.getCredentials();

    const person = Object.assign({}, personIn);

    /*person.notes = person.notes;
    person.interests = person.interests;
    person.phone_mobile = person.phone_mobile;
    person.phone_line = person.phone_line;
    person.role = person.role;
    person.iban = person.iban;
    person.bic = person.bic;
    person.depositor = person.depositor;*/


    person.phone_line = encodeURIComponent(person.phone_line);
    person.phone_mobile = encodeURIComponent(person.phone_mobile);

    // person.role = encodeURIComponent(person.role);

    http
      .post(URLs.PERSON_UPDATE, {person}, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`,
        })
      })
      .subscribe((response: APIResponse) => {
          if (response.status === 200) {
            success(response.data);
          } else {
            error(response.error);
          }
          always();
        },
        response => {
          error(response.error.error);
          always();
        });
  }

  public static personCreate(http: HttpClient, personIn: Person,
                             success = this.defFuncWithParam,
                             error = this.defFuncWithParam,
                             always = this.defFunc): void {

    const credentials = APIHelper.getCredentials();

    const person = Object.assign({}, personIn);
    person.phone_line = encodeURIComponent(person.phone_line);
    person.phone_mobile = encodeURIComponent(person.phone_mobile);

    http
      .post(URLs.PERSON_CREATE, {
        person
      }, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`,
        })
      })
      .subscribe((response: APIResponse) => {
          if (response.status === 200) {
            success(response.data);
          } else {
            error(response.error);
          }
          always();
        },
        response => {
          error(response.error.error);
          always();
        });
  }

  public static personDelete(http: HttpClient, id: number,
                             success = this.defFunc,
                             error = this.defFuncWithParam,
                             always = this.defFunc): void {

    const credentials = APIHelper.getCredentials();

    http
      .post(URLs.PERSON_DELETE, {id}, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`,
        })
      })
      .subscribe((response: APIResponse) => {
          if (response.status === 200) {
            success();
          } else {
            error(response.error);
          }
          always();
        },
        response => {
          error(response.error.error);
          always();
        });
  }

  public static countries(http: HttpClient,
                          success = this.defFuncWithParam,
                          error = this.defFuncWithParam,
                          always = this.defFunc): void {

    http.get(URLs.COUNTRIES, this.httpOptions)
      .subscribe((response: APIResponse) => {
          success(response.data);
          always();
        },
        response => {
          error(response.error.error);
          always();
        });
  }
}

interface APIResponse {
  status: number;
  data: any;
  error: string;
}

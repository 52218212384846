import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'filterPeople'})
export class FilterPeoplePipe implements PipeTransform {
  public transform(people: any[], filter: string): any[] {
    if (!people || !people.length) {
      return [];
    }
    if (!people) {
      return people;
    }

    return people.filter(v => {
      const filterLower = filter.toLowerCase();
      return v.first_name.toLowerCase().indexOf(filterLower) >= 0
        || v.last_name.toLowerCase().indexOf(filterLower) >= 0
        || (v.last_name.toLowerCase() + ' ' + v.first_name.toLowerCase()).indexOf(filterLower) >= 0
        || (v.first_name.toLowerCase() + ' ' + v.last_name.toLowerCase()).indexOf(filterLower) >= 0
        || v.email.toLowerCase().indexOf(filterLower) >= 0
        || v.pre_salutation.toLowerCase().indexOf(filterLower) >= 0
        || v.salutation.toLowerCase().indexOf(filterLower) >= 0
        || v.member_id.toLowerCase().indexOf(filterLower) >= 0;
    });
  }
}

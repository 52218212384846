<mat-progress-bar *ngIf="isLoading" color="accent" mode="indeterminate"></mat-progress-bar>
<main *ngIf="(id === -1 || id > 0) && !isLoading">
  <header>
    <img src="./assets/kv_ic_person.png" id="person-icon">
    <div id="name">
      <div id="name-content">
        <span
          *ngIf="person.pre_salutation != null && person.pre_salutation != '' || person.salutation != null && person.salutation != ''">{{person.pre_salutation}} {{person.salutation}} </span>{{person.title}}
        <b>{{person.last_name}}</b> {{person.first_name}}<span
        *ngIf="person.last_name == '' && person.first_name == ''">{{person.email}}</span>
      </div>
    </div>
    <div class="clear"></div>
    <br>
    <div class="tiny-info">Mitgliedsnummer: <b><span
      [ngClass]="person.member_id != '' ? 'text-black' : ''"
      *ngIf="person.member_id != ''; else noMemberId">{{person.member_id}}</span></b></div>
    <ng-template #noMemberId>Bitte Land zuweisen!</ng-template>
  </header>


  <div *ngIf="!isEditing; else editForm">

    <mat-card class="card">
      <header>Newsletter</header>

      <div class="state-row">
        <span><mat-icon class="inline-text"
                        [ngClass]="person.is_receiving_mail_news != null && person.is_receiving_mail_news == 1 ? 'green' : 'grey'">
          {{person.is_receiving_mail_news == 1 ?
          'check_circle' : 'cancel'}}
        </mat-icon>Post</span>
      </div>
      <div class="state-row">
        <span><mat-icon class="inline-text"
                        [ngClass]="person.is_receiving_email_news != null && person.is_receiving_email_news == 1 ? 'green' : 'grey'">
          {{person.is_receiving_email_news == 1 ?
          'check_circle' : 'cancel'}}
        </mat-icon>E-Mail</span>
      </div>
      <div class="state-row">
        <span><mat-icon class="inline-text"
                        [ngClass]="person.is_receiving_seminar_news != null && person.is_receiving_seminar_news == 1 ? 'green' : 'grey'">
          {{person.is_receiving_seminar_news == 1 ?
          'check_circle' : 'cancel'}}
        </mat-icon>Seminar</span>
      </div>
    </mat-card>


    <mat-card class="card">
      <header>Echtheit &amp; Mitgliedschaft</header>

      <div class="state-row">
        <span><mat-icon class="inline-text" [ngClass]="person.is_confirmed == 1 ? 'green' : 'red'">
          {{person.is_confirmed != null && person.is_confirmed == 1 ?
          'check_circle' : 'cancel'}}
        </mat-icon>Echtheit bestätigt</span>
      </div>
      <div class="state-row">
        <span><mat-icon class="inline-text" [ngClass]="isMember() ? 'green' : 'grey'">
          {{isMember() ? 'check_circle' : 'cancel'}}
        </mat-icon>Mitglied</span>
      </div>
      <!--<div class="state-row" *ngIf="person.is_member == 1">
        <span><mat-icon class="inline-text" [ngClass]="person.year_paid_membership >= currentYear  ? 'green' : 'red'">
          {{person.year_paid_membership >= currentYear ? 'check_circle' : 'cancel'}}
        </mat-icon>
          {{person.year_paid_membership <= 0
                ? 'Beitrag noch nie eingezahlt'
                : (person.year_paid_membership >= currentYear ? 'Mitglied bis Ende ' + person.year_paid_membership : 'Kein Mitglied seit ' + person.year_paid_membership)}}
        </span>
      </div>-->
      <mat-chip-list *ngIf="person.memberships.length > 0">
        <mat-chip *ngFor="let year of person.memberships">{{year}}</mat-chip>
      </mat-chip-list>
    </mat-card>


    <mat-card class="card" *ngIf="person.bank_depositor != '' || person.bank_iban != '' || person.bank_bic != ''">
      <header>Kontodaten</header>

      <table>
        <tbody>
        <tr *ngIf="person.bank_depositor != null && person.bank_depositor != ''">
          <td><b>Inhaber&ensp;</b></td>
          <td>{{person.bank_depositor}}</td>
        </tr>
        <tr *ngIf="person.bank_iban != null && person.bank_iban != ''">
          <td><b>IBAN</b></td>
          <td>{{person.bank_iban | IbanPipe}}</td>
        </tr>
        <tr *ngIf="person.bank_bic != null && person.bank_bic != ''">
          <td><b>BIC</b></td>
          <td>{{person.bank_bic}}</td>
        </tr>
        </tbody>
      </table>

    </mat-card>

    <mat-card class="card" *ngIf="person.email != '' || person.phone_line != '' || person.phone_mobile != ''">
      <header>Kontakt</header>

      <span *ngIf="person.email != null && person.email != ''">
        <mat-icon class="inline-text grey">mail</mat-icon><a
        href="mailto:{{person.email}}">{{person.email}}</a><br></span>
      <span *ngIf="person.phone_line != null && person.phone_line != ''">
        <mat-icon class="inline-text grey">phone</mat-icon>
        {{person.phone_line}}<br></span>
      <span *ngIf="person.phone_mobile != null && person.phone_mobile != ''">
        <mat-icon class="inline-text grey">smartphone</mat-icon>
        {{person.phone_mobile}}</span>
    </mat-card>

    <mat-card class="card"
              *ngIf="person.address_street != '' || person.address_zipcode != '' || person.address_city != '' || person.country != null">
      <!-- todo: <mat-card class="card" *ngIf="person.street != '' || person.postcode != '' || person.city != '' || person.country != null"> -->
      <header>Adresse</header>

      <span *ngIf="person.address_street != ''">{{person.address_street}}<br></span>
      <span
        *ngIf="person.address_zipcode!= '' || person.address_city != ''">{{person.address_zipcode}} {{person.address_city}}
        <br></span>
      <span *ngIf="person.country != null">{{person.country.name}}</span>
    </mat-card>

    <mat-card class="card" *ngIf="person.interests != ''">
      <header>Interessen</header>
      <div [innerHTML]="person.interests | replaceLineBreaks"></div>
    </mat-card>

    <mat-card class="card" *ngIf="person.role != ''">
      <header>Funktion im Verein</header>
      <div [innerHTML]="person.role | replaceLineBreaks"></div>
    </mat-card>

    <mat-card class="card" *ngIf="person.notes != ''">
      <header>Notizen</header>
      <div [innerHTML]="person.notes | replaceLineBreaks"></div>
    </mat-card>

  </div>

  <ng-template #editForm>
    <mat-card class="card">
      <header>Name</header>

      <mat-grid-list cols="2" rowHeight="65px">
        <mat-grid-tile>
          <mat-form-field>
            <input matInput placeholder="Anrede" [(ngModel)]="person.salutation">
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile>
          <mat-form-field>
            <input matInput placeholder="Titel" [(ngModel)]="person.title">
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-form-field>
        <input matInput placeholder="Nachname" [(ngModel)]="person.last_name">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Vorname" [(ngModel)]="person.first_name">
      </mat-form-field>
    </mat-card>


    <mat-card class="card">
      <header>Kontakt</header>

      <mat-form-field>
        <input matInput placeholder="E-Mail" [(ngModel)]="person.email">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Telefon (Festnetz)" [(ngModel)]="person.phone_line">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Telefon (Mobil)" [(ngModel)]="person.phone_mobile">
      </mat-form-field>
    </mat-card>


    <mat-card class="card">
      <header>Echtheit &amp; Mitgliedschaft</header>

      <mat-checkbox class="inline-checkbox" color="primary"
                    [checked]="person.is_confirmed != null && person.is_confirmed == 1"
                    [(ngModel)]="person.is_confirmed"><span
        [ngClass]="person.is_confirmed == 1 ? 'text-green' : 'text-red'">Echtheit bestätigt</span>
      </mat-checkbox>
      <div>
        <mat-list role="list" *ngIf="person.memberships.length > 0" class="membership-list">
          <mat-list-item role="listitem" *ngFor="let year of person.memberships">
            <h4 mat-line>Mitglied <b>{{year}}</b></h4>
            <button mat-icon-button (click)="removeMembershipYear(year)" matTooltip="Mitgliedschaft entfernen">
              <mat-icon>close</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
        <div *ngIf="availableMembershipYears.length > 0">
          <br>
          <div style="font-weight: bold;">Mitgliedschaft hinzufügen</div>
          <button mat-stroked-button *ngFor="let year of availableMembershipYears"
                  (click)="addMembershipYear(year)">{{year}}
          </button>
        </div>
      </div>
    </mat-card>


    <mat-card class="card">
      <header>Newsletter</header>

      <mat-checkbox class="inline-checkbox" color="primary"
                    [checked]="person.is_receiving_mail_news != null && person.is_receiving_mail_news == 1"
                    [(ngModel)]="person.is_receiving_mail_news"
                    [ngClass]="person.is_receiving_mail_news == 1 ? 'text-green' : 'text-red'">Post
      </mat-checkbox>
      <mat-checkbox class="inline-checkbox" color="primary"
                    [checked]="person.is_receiving_email_news != null && person.is_receiving_email_news == 1"
                    [(ngModel)]="person.is_receiving_email_news"
                    [ngClass]="person.is_receiving_email_news == 1 ? 'text-green' : 'text-red'">E-Mail
      </mat-checkbox>
      <mat-checkbox class="inline-checkbox" color="primary"
                    [checked]="person.is_receiving_seminar_news != null && person.is_receiving_seminar_news == 1"
                    [(ngModel)]="person.is_receiving_seminar_news"
                    [ngClass]="person.is_receiving_seminar_news == 1 ? 'text-green' : 'text-red'">Seminar
      </mat-checkbox>
    </mat-card>


    <mat-card class="card">
      <header>Kontodaten</header>

      <mat-form-field>
        <input matInput placeholder="Kontoinhaber" [(ngModel)]="person.bank_depositor">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="IBAN" [(ngModel)]="person.bank_iban">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="BIC" [(ngModel)]="person.bank_bic">
      </mat-form-field>
    </mat-card>

    <mat-card class="card">
      <header>Adresse</header>

      <mat-form-field>
        <input matInput placeholder="Straße" [(ngModel)]="person.address_street">
      </mat-form-field>
      <br>

      <mat-grid-list cols="3" rowHeight="65px">
        <mat-grid-tile>
          <mat-form-field class="first">
            <input matInput placeholder="PLZ" [(ngModel)]="person.address_zipcode">
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <mat-form-field class="second">
            <input matInput type="text" placeholder="Ort" aria-label="Number"
                   (input)="filter($event.target.value)" [matAutocomplete]="auto" [(ngModel)]="person.address_city">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let place of filteredPlaces" [value]="place">
                {{place}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-form-field>
        <mat-select placeholder="Land" [(ngModel)]="person.country_id">
          <mat-option *ngFor="let country of countries"
                      [value]="country.id">{{country.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card>


    <mat-card class="card">
      <mat-form-field>
        <textarea matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  placeholder="Interessen"
                  [(ngModel)]="person.interests"></textarea>
      </mat-form-field>
    </mat-card>

    <mat-card class="card">
      <mat-form-field>
        <textarea matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  placeholder="Funktion im Verein"
                  [(ngModel)]="person.role"></textarea>
      </mat-form-field>
    </mat-card>

    <mat-card class="card">
      <mat-form-field>
        <textarea matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  placeholder="Notizen"
                  [(ngModel)]="person.notes"></textarea>
      </mat-form-field>
    </mat-card>

  </ng-template>

  <div class="clear"></div>

  <div class="section" *ngIf="person.id > 0">
    <div class="tiny-info">
      <span *ngIf="person.time_updated != null">Letzte Änderung {{person.time_updated | date:'short'}}</span><br>
      Erstellt {{person.time_created | date:'short'}}
    </div>
  </div>

  <div class="section" id="logs" *ngIf="person.id > 0 && logs.length > 0">
    <br><br>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Änderungsprotokoll
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table>
        <tbody>
          <tr *ngFor="let log of logs"><td><b>{{log.time_created | date:'short'}}</b></td><td><span
            class="message">{{log.message}}</span></td></tr>
        </tbody>
      </table>
      <br>
      <div>Zeiten sind in UTC angegeben.</div>
    </mat-expansion-panel>
  </div>

  <div class="actions">
    <button mat-button color="accent" *ngIf="!isEditing" (click)="isEditing = true">
      <mat-icon class="format">edit</mat-icon>
      BEARBEITEN
    </button>
    <button mat-button color="primary" *ngIf="isEditing" (click)="save()">
      <mat-icon class="format">check_circle</mat-icon>
      SPEICHERN
    </button>
    <button mat-button color="accent" *ngIf="isEditing" (click)="cancel()">
      <mat-icon class="format">cancel</mat-icon>
      ABBRECHEN
    </button>

    <button mat-button color="warn" *ngIf="!isEditing" (click)="deletePerson()">
      <mat-icon class="format">delete</mat-icon>
      LÖSCHEN
    </button>
  </div>
</main>

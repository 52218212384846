import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Credentials} from '../../object/Credentials';
import {API} from '../../API';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isSigningIn = false;
  username = '';
  password = '';

  constructor(private router: Router, private http: HttpClient) {
  }

  ngOnInit(): void {
  }

  signIn(): void {
    const cred = new Credentials();
    cred.username = this.username;
    cred.password = this.password;

    API.signIn(this.http, cred, (data) => {
      localStorage.username = this.username;
      localStorage.password = this.password;

      this.router.navigateByUrl('/dashboard');
    }, (error) => {
      alert('Anmeldedaten fehlerhaft!');
    });
  }
}

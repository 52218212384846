<!-- content -->
<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" opened="true">

    <div class="profile">
      <div class="text">
        Willkommen!
      </div>
      <br>

      <!--<mat-form-field
        *ngIf="userService.user != null && userService.user.organization_users != null && userService.user.organization_users.length > 0">
        <mat-select placeholder="Organisation" [(ngModel)]="userService.selectedOrganizationId"
                    (valueChange)="userService.organizationSelectionChanged()">
          <mat-option *ngFor="let ouser of userService.user.organization_users"
                      [value]="ouser.organization_id">{{ouser.organization.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>-->
    </div>

    <!--<div mat-ripple
         (click)="userService.toggleStatus()"
         matTooltip="Tippe zum Ändern."
         id="user-status"
         [class.loading]="userService.user.latest_user_state == null"
         [class.available]="userService.user.latest_user_state != null && userService.user.latest_user_state.is_available"
         [class.busy]="userService.user.latest_user_state != null && !userService.user.latest_user_state.is_available">
      <mat-icon *ngIf="userService.user.latest_user_state != null">
        notifications_{{userService.user.latest_user_state != null &&
        userService.user.latest_user_state.is_available ? 'active' : 'off'}}
      </mat-icon>
      {{userService.user.latest_user_state == null ? 'Laden ...' :
      (userService.user.latest_user_state.is_available ? 'VERFÜGBAR' : 'NICHT VERFÜGBAR')}}
    </div>-->

    <ul id="nav-menu">
      <li *ngFor="let link of mainMenu" [routerLink]="'/dashboard/'+link.link" class="noselect"
          routerLinkActive="active">
        <mat-icon>{{link.icon}}</mat-icon>
        <span>{{link.text}}</span>
        <div class="clear"></div>
      </li>
    </ul>

  </mat-sidenav>

  <mat-toolbar color="primary">
    <span>Kulturverein Administration</span>
    <span class="app-toolbar-filler"></span>

    <button mat-icon-button (click)="signOut()">
      <mat-icon>exit_to_app</mat-icon>
    </button>

  </mat-toolbar>

  <div class="app-content">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from './site/dashboard/dashboard.component';
import {AuthGuard} from './guard/auth.guard';
import {HomeComponent} from './site/dashboard/home/home.component';
import {PeopleComponent} from './site/dashboard/people/people.component';
import {PersonComponent} from './site/dashboard/person/person.component';
import {LoginComponent} from './site/login/login.component';
import {NotFoundComponent} from './site/error/not-found/not-found.component';

const routes: Routes = [
  // Default
  {path: '', redirectTo: 'login', pathMatch: 'full'},

  // Dashboard
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], children:
      [
        {path: '', redirectTo: 'people', pathMatch: 'full'},
        {path: 'home', component: HomeComponent},
        // {path: 'articles', component: ArticlesComponent},
        {path: 'people', component: PeopleComponent},
        {path: 'people/:id', component: PersonComponent},
      ]
  },

  // Login
  {path: 'login', component: LoginComponent},

  // Errors
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
